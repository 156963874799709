@import "~mondrian-tokens/dist/claro-tokens-latest";

// All projet Colors
$color-button: var(--color-support-highlight-medium);
$color-fields-border: var(--color-support-highlight-medium);
$color-button-hover: var(--color-support-hightlight-dark);
$color-dark-red: var(--color-brand-primary-dark);
$color-main: var(--color-brand-primary-medium);
$color-pink: var(--color-brand-primary-light);
$color-black: var(--color-neutral-darkest);
$color-text: var(--color-neutral-dark);
$color-gray-dark: var(--color-neutral-dark);
$color-gray-super-dark: rgba($color-neutral-darkest, $opacity-semi-opaque);
$color-gray: #9ea3ae;
$color-gray-brighter: var(--color-neutral-medium);
$color-white-gray: var(--color-neutral-light);
$color-base-brighter: var(--color-neutral-lightest);
$color-white: var(--color-neutral-lightest);
$color-green: #4bb00c;
$color-transparent: transparent; 

/* All Fonts */
$font-normal: var(--font-family-base);
$font-bold: var(--font-family-highlight);
$font-icons: "icons";
$font-material-icons: "Material Icons";

/* load */
:root {
    --llc_image_width: 100px;
    --llc_image_height: calc((var(--llc_image_width) / 30) + var(--llc_image_width)) ;
    --llc_part: calc(var(--llc_image_width) / 40) ;
    --llc_color_base: var(--color-brand-primary-medium);
    --llc_time_animation: 1s;
  }