/* You can add global styles to this file, and also import other style files */
@import "assets/scss/_variables.scss";
@import "~mondrian-tokens/dist/claro-tokens-latest.scss";


html,
body {
  min-height: 100vh;
  font-family: var(--theme-font-family--base) !important;
}

app-selo   {margin: auto;}

.muda-bg {
  background: #f8f9fb !important;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow-y: hidden !important;
}

.cdk-global-overlay-wrapper {
  pointer-events: auto !important; // be able to scroll event
  overflow-y: auto !important;
  align-items: start !important; // not center the dialog
  z-index: auto;
}

.cdk-overlay-pane {
  margin: auto;
  max-width: 90vw !important;
}

.cdk-overlay-container {
  z-index: 999;
}

footer {
  margin-top: 0 !important;
}

// Remove Arrows/Spinners
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.icon {
  display: inline-block;
}

.row {
  margin: 0;
  padding: 0;
}

.link-return {
  text-decoration: none;
  div {
    padding-top: 20px;
    float: left !important;
    color: $color-black !important;
  }

  em {
    padding-right: 5px !important;
    color: $color-black !important;
  }
}

//material css custom
.mat-dialog-container {
  overflow: initial !important;
  padding: 0px !important;
  border-radius: 8px !important;
}

.mat {
  &-focused {
    .mat-form-field {
      &-label {
        color: $color-fields-border !important;
      }

      &-required-marker {
        color: $color-fields-border !important;
      }
    }
  }
  &-form-field-invalid {
    &.ng-invalid {
      margin-bottom: 24px;
    }
  }
}

.mat-form-field-ripple {
  background-color: $color-fields-border !important;
}

// mat radio wrapper
.mat-radio-outer-circle {
  border-color: $color-main !important;
}

.mat-radio-inner-circle {
  background-color: $color-main !important;
}

.mat-selected-value {
  color: $color-black !important;
}

.mat-option-text {
  color: $color-black !important;
}

.mat-calendar-body-selected {
  background-color: $color-main !important;
}

.mat-calendar {
  min-height: 354px;
  height: auto !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: $color-button !important;
}

form[name="formCouponPayment"] {
  .mat-form-field.ng-pending .mat-form-field-ripple,
  .mat-form-field.ng-pending .mat-form-field-label {
    color: $color-button !important;
  }
  .mat-form-field.ng-pending.mat-primary.mat-form-field-appearance-legacy
    .mat-form-field-underline {
    background-color: $color-button !important;
  }
}
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent
  .mat-checkbox-ripple
  .mat-ripple-element {
  background-color: $color-main !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $color-main !important;
}

.text-danger .mat-checkbox-frame {
  border-color: $color-main !important;
}

.form-data-real {
  .mat-button-ripple.mat-ripple {
    display: none !important;
  }
  .mat-datepicker-toggle-active {
    color: rgba(0, 0, 0, 0.54);
  }
}

.mat-form-field-hint-spacer {
  flex: 0 !important;
}

.closeDialog {
  position: absolute;
  background-color: #999;
  color: var(--theme-text-color--secondary);
  top: 0;
  right: 0;
  font-size: calc(var(--theme-font-size-xxs) - 1px);
  border: none;
  padding: 0;
  width: 22px;
  height: 22px;
}

.form-input {
  width: 100%;
  height: auto;
}

.mat-error {
  font-size: 88%;
}

.mat-tab-header {
  margin-bottom: 4%;
}

.mat-tab-label {
  color: $color-fields-border;
}

.mat-tab-label-active {
  color: $color-text;
}

.mat-checkbox-inner-container {
  margin: 0 8px 0 0 !important;
}

.plano-adc {
  margin: auto;
  max-width: 830px !important;
  width: 100%;
  max-height: 146px;
  right: 27%;
  top: 105px;
  position: absolute !important;

  &:before {
    content: " ";
    width: 10px;
    height: 10px;
    background: transparent;
    position: absolute;
    margin-top: 40px;
    right: -5px;
    border: 5px solid var(--theme-color-neutral-lightest);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-radius: 1px;
    border-top: 0px;
    border-left: 0px;
  }

  &-single {
    max-width: 258px !important;
    height: 60.78px;
    width: 257px;
    top: 120px !important;
    border-bottom: 1px;

    &:before {
      margin-top: 23px !important;
      border: 7px solid $color-green !important;
      border-radius: 4px !important;
      right: -8px !important;
    }

    .modal-header {
      height: 61px !important;
      padding: 20px 16px !important;
      border-radius: 8px 8px 8px 8px !important;
      border-bottom: 1px !important;

      h5 {
        letter-spacing: -0.8px !important;
      }
    }
  }

  .mat-dialog-container {
    padding: 0;
    border-radius: 8px !important;
  }
}

.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: $color-main !important;

    &.mat-accent,
    .mat-form-field-required-marker {
      color: $color-main !important;
    }
  }

  .mat-form-field-ripple,
  .mat-form-field-ripple.mat-accent {
    background-color: $color-main !important;
  }
}

.modal-content {
  border-radius: 8px !important;
}

@media only screen and (max-width: 1310px) and (min-width: 1200px) {
  .plano-adc {
    right: 24%;

    &:before {
      right: -2px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .plano-adc {
    margin: auto;
    position: static !important;
    margin-top: 52px;
    margin-right: 7px;
    max-width: 300px !important;
    max-height: 370px;
    right: 7px !important;

    &:before {
      display: none;
    }

    &-single {
      #modal-turbine-modal-lg {
        border-radius: 8px !important;

        &:before {
          background: $color-green !important;
          border-color: $color-green !important;
        }

        .modal {
          &-content {
            padding: 0 !important;
          }

          &-header {
            background: $color-green !important;

            h5 {
              color: $color-white !important;
            }
          }
        }
      }
    }
  }
}

.btn-default {
  &.disabled {
    cursor: default;
    color: $color-white !important;
    background-color: $color-gray !important;

    &:hover {
      background-color: $color-gray !important;
      color: $color-white !important;
    }
  }

  cursor: pointer;
  width: 96%;
  position: absolute;
  margin: 15px 8px;
  height: 42px;
  border-radius: 22px;
  color: $color-black !important;
  background-color: $color-button !important;
  border: none;
  font-family: $font-normal;
  font-size: var(--theme-font-size-xxs);
  font-weight: var(--theme-font-weight-medium);
  line-height: 16px;
  text-align: center;
  clear: both;
  padding: 12px 0;
  box-sizing: border-box !important;
  vertical-align: middle;

  &:hover {
    background-color: $color-button-hover !important;
  }
}

.carrinho-botao {
  border: 0;
  border-radius: 0 0 18px 18px;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-atencao:before {
  content: "\e93d";
}

.icon-checked:before {
  content: "\e93c";
}

.icon-voltar:before {
  content: "\e900";
}

.icon-youtube:before {
  content: "\e901";
}

.icon-wi-fi:before {
  content: "\e902";
}

.icon-utilidades:before {
  content: "\e903";
}

.icon-twiter:before {
  content: "\e904";
}

.icon-tv:before {
  content: "\e905";
}

.icon-tablet:before {
  content: "\e906";
}

.icon-sms:before {
  content: "\e907";
}

.icon-sinal:before {
  content: "\e908";
}

.icon-seta_up:before {
  content: "\e909";
}

.icon-seta_down:before {
  content: "\e90a";
}

.icon-saude:before {
  content: "\e90b";
}

.icon-satelite:before {
  content: "\e90c";
}

.icon-residencial:before {
  content: "\e90d";
}

.icon-protecao:before {
  content: "\e90e";
}

.icon-prosseguir:before {
  content: "\e90f";
}

.icon-portabilidade:before {
  content: "\e910";
}

.icon-ponto_adicional .path1:before {
  content: "\e911";
  color: rgb(32, 32, 32);
  opacity: 0.99;
}

.icon-ponto_adicional .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: var(--theme-color-neutral-darkest);
}

.icon-ponto_adicional .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: var(--theme-color-neutral-darkest);
}

.icon-ponto_adicional .path4:before {
  content: "\e914";
  margin-left: -1em;
  color: var(--theme-color-neutral-darkest);
}

.icon-perguntas_frequentes:before {
  content: "\e915";
}

.icon-pedido:before {
  content: "\e916";
}

.icon-pacotes_adicionais:before {
  content: "\e917";
}

.icon-modem:before {
  content: "\e918";
}

.icon-minha_claro:before {
  content: "\e919";
}

.icon-menu:before {
  content: "\e91a";
}

.icon-mapa:before {
  content: "\e91b";
}

.icon-mais:before {
  content: "\e91c";
}

.icon-loja:before {
  content: "\e91d";
}

.icon-localizacao:before {
  content: "\e91e";
}

.icon-linkdin:before {
  content: "\e91f";
}

.icon-ligacoes:before {
  content: "\e920";
}

.icon-libras:before {
  content: "\e921";
}

.icon-internet:before {
  content: "\e922";
}

.icon-instalacao:before {
  content: "\e923";
}

.icon-instagram:before {
  content: "\e924";
}

.icon-informacoes:before {
  content: "\e925";
}

.icon-gravador:before {
  content: "\e926";
}

.icon-gratis:before {
  content: "\e927";
}

.icon-fone-fixo:before {
  content: "\e928";
}

.icon-fechar:before {
  content: "\e929";
}

.icon-fale_conosco:before {
  content: "\e92a";
}

.icon-facebook:before {
  content: "\e92b";
}

.icon-esporte:before {
  content: "\e92c";
}

.icon-empresa:before {
  content: "\e92d";
}

.icon-download:before {
  content: "\e92e";
}

.icon-documento:before {
  content: "\e92f";
}

.icon-descontos:before {
  content: "\e930";
}

.icon-deficiencia:before {
  content: "\e931";
}

.icon-debito_automatico:before {
  content: "\e932";
}

.icon-computador:before {
  content: "\e933";
}

.icon-chip:before {
  content: "\e934";
}

.icon-chat:before {
  content: "\e935";
}

.icon-celular:before {
  content: "\e936";
}

.icon-carrinho:before {
  content: "\e937";
}

.icon-cabo:before {
  content: "\e938";
}

.icon-busca:before {
  content: "\e939";
}

.icon-bloqueio:before {
  content: "\e93a";
}

.icon-ajustavel:before {
  content: "\e93b";
}

@media only screen and (max-width: 769px) {
  html,
  body {
    min-height: 100vh;
    height: 100%;
    background: $color-base-brighter;
  }
}

a,
button {
  outline: none !important;
  &:focus {
    outline: none !important;
  }
}

#modal-detalhe-produto-carrinho {
  .mat-tab-label {
    border-radius: 8px 8px 0 0;
    color: $color-text;
    background: $color-gray-brighter;
    transition: background-color 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    opacity: 1;
    margin: 0 1px;
  }
  .mat-tab-label-active {
    background: $color-main;
    color: $color-white;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: $color-dark-red;
  }
}

@-moz-document url-prefix() {
  .btn-default {
    line-height: 21px !important;
  }
}

.form-input-schedule {
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-flex {
    border-bottom: 0.7px solid $color-gray-super-dark;
  }
  .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}
form[name="endereco"] {
  .mat-form-field-suffix {
    width: 100% !important;
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
  }
  .date-picker-toggle,
  .mat-datepicker-toggle {
    width: 100%;
    position: relative;
    height: 100%;
    float: right;
    button {
      &.mat-icon-button {
        position: relative;
        float: right;
        width: 100%;
        height: 100%;
        .mat-button-wrapper {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          text-align: right;
          clear: both;
          box-sizing: border-box;

          .mat-icon {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-label,
.mat-form-field-disabled .mat-form-field-flex,
.mat-radio-disabled .mat-radio-outer-circle {
  opacity: 0.5;
}

#pageName .row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.tabs.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mat-spinner {
  margin: auto;

  circle {
    stroke: $color-brand-primary-medium;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-portabilidade-fixo .mdn-Input-errorFeedback {
  padding-left: 10px;
  padding-right: 10px;
}
.ht-skip {
  display: none;
}
.ht-skip.show {
  display: block;
}

.mdn-Theme--contrast {
  .container {
    .local-instalacao-btn {
      .btn {
        background-color: var(--theme-background-color--light) !important;
        border: 1px solid var(--theme-border-color--contrast--clickable) !important;
        color: var(--theme-border-color--contrast--clickable);

        .mdn-Text {
          color: var(--theme-border-color--contrast--clickable) !important;
        }
      }
    }
  }


  .card-container {
    .mdn-Text {
      color: var(--theme-text-color--primary) !important;
    }

    .mdn-Icon--sm {
      color: var(--theme-text-color--primary) !important;
    }

    .card-price {
      background-color: var(--theme-background-color--light) !important;

      .mdn-Button {
        border-color: var(--theme-border-color-Button-hover--primary) !important;
      }
    }
  }
  .rodape-desc {
    .mdn-Accordion {
      .mdn-Accordion-box {
        background-color: var(--theme-border-color--contrast--clickable);

        .mdn-Text--body {
          color: var(--theme-color-Accordion-toggle-default) !important;
        }
      }
      .mdn-Accordion-content {
        .mdn-Text--body {
          color: var(--theme-text-color-Text--default) !important;
        }
      }
    }
  }

  .c-resources-mobile__box--title {
    .c-box-header__label {
      color: var(--theme-border-color--contrast--clickable);
    }

    .c-content__image {
      img {
        border-radius: 50%;
        border: 2px solid var(--theme-border-color--contrast--clickable);
      }
    }
  }

  .c-resources-mobile__box--content {
    .c-content__title {
      text-decoration: underline !important;
      color: var(--theme-text-color-Button--global) !important;
    }

    .c-content__image {
      img {
        border-radius: 50%;
        border: 2px solid var(--theme-border-color--contrast--clickable);
      }
    }
  }

  .c-resources-mobile__box--title {
    .mdn-Icon-baixo {
      color: var(--theme-text-color-Button--global) !important;
    }
  }

  .more-info {
    .mdn-Text,
    .mdn-Icon-alerta {
      color: var(--theme-text-color-Button--global) !important;
    }

    .mdn-Text {
      text-decoration: underline !important;
    }
  }

  .c-resources-desktop__content--item {
    .item-title {
      color: var(--theme-text-color-Button--global) !important;
    }

    .item-image img {
      border-radius: 50%;
      border: 2px solid var(--theme-border-color--contrast--clickable);
    }
  }

  .c-button-payment-mobile {
    background-color: transparent !important;
  }

  .is-expanded {
    .mdn-Accordion-toggle {
      background-color: transparent !important;
      color: var(--theme-color-neutral-lightest) !important;
    }
  }

  .mdn-Accordion-toggle {
    background-color: transparent !important;
  }

  .mdn-TabSelect {
    background-color: var(--theme-background-color--darkest) !important;
    color: var(--theme-text-color--inverse);
  }

  .mdn-Subtitle {
    color: var(--theme-text-color--inverse) !important;
  }

  .mdn-Heading {
    color: var(--theme-text-color--inverse) !important;
  }

  .mdn-Link:hover {
    color: var(--theme-text-color-Link--inverse) !important;
    text-decoration: none !important;
  }

  .mdn-Link-anchor {
    color: var(--theme-text-color-Link--inverse) !important;
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  .img-claro-movel {
    filter: brightness(0) invert(1);
  }

  .mdn-Menu-logo {
    filter: brightness(0) saturate(100%) invert(97%) sepia(85%) saturate(4468%)
      hue-rotate(326deg) brightness(110%) contrast(109%) !important;
  }

  .mdn-Footer-disclaimer a {
    color: var(--theme-text-color-Link--inverse) !important;
  }

  .mdn-Menu-top-list-item > button,
  a {
    text-decoration: none !important;
    padding: 0 var(--theme-spacing-inline-xxs);
  }

  .underline {
    text-decoration: underline !important;
  }

  .mdn-Link-anchor,
  .table.product thead th h3,
  .icon-informacoes {
    color: var(--theme-text-color-Link--inverse) !important;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }

    .mdn-Link-anchor-icon {
      text-decoration: underline;
    }
  }

  .mdn-Input--hintFeedback {
    .mdn-Input-field {
      border-color: var(--theme-color-Input--attention) !important;
    }
    .mdn-Input-hintFeedback {
      color: var(--theme-color-Input--attention);
    }
  }

  a,
  .mdn-Link,
  .component-formCouponPayment-init span {
    color: var(--theme-text-color-Button--global) !important;
    text-decoration: underline !important;

    &:hover {
      color: var(--theme-text-color-Button--global) !important;
      text-decoration: none !important;
    }

    u,
    .icon-seta_down {
      &:hover {
        text-decoration: none !important;
      }
    }

    .icon-seta_down,
    .icon-seta_up {
      color: var(--theme-text-color-Button--global) !important;
    }
  }

  .mdn-Radio-text,
  .mdn-Radio .mdn-Radio-text,
  .mdn-Radio .mdn-Radio-text span {
    color: var(--theme-textColor-Radio-default) !important;
    text-decoration: underline;
  }

  .mdn-Radio:hover {
    .mdn-Radio-text {
      text-decoration: none;
      span {
        text-decoration: none;
      }
    }
  }

  .mdn-Input-field[disabled] ~ label {
    opacity: var(--theme-opacity-medium);
  }

  .mdn-Link.mdn-Link--arrow:hover,
  .component-formCouponPayment-init:hover {
    i,
    .mdn-Link-anchor,
    span {
      text-decoration: none !important;
    }
  }

  .mdn-Modal-content {
    border: 1px solid var(--theme-color-neutral-lightest);

    .mdn-Modal-body {
      p {
        color: var(--theme-color-neutral-lightest);
      }
    }

    .mdn-Modal-body::-webkit-scrollbar-track {
      background: var(--theme-background-color--darkest);
      -webkit-box-shadow: inset 0 0 3px var(--theme-color-neutral-lightest);
    }

    .ng-star-inserted,
    .titleDescricao,
    .mdn-Button-header-close,
    .form-container {
      background-color: var(--theme-color-neutral-darkest) !important;
    }

    .description {
      color: var(--theme-color-neutral-lightest);

      li {
        color: var(--theme-color-neutral-lightest);
      }
    }
  }

  select.mdn-Input-field {
    background-image: linear-gradient(45deg, transparent 50%, white 50%),
      linear-gradient(135deg, white 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  .fullAddressText,
  .fullAddressLabel,
  .mdn-Input-field[disabled],
  .mdn-Input-field[disabled] ~ label,
  .mdn-Text--body,
  .ambiente-seguro-footer,
  .form-group label,
  .mdn-Calendar-monthDays-day--locked .mdn-Calendar-monthDays-day-anchor,
  .text-danger,
  .component-cupom-desconto h4,
  .destaque-header-item,
  .icon-internet,
  .mdn-Icon-internet,
  .icon-tv,
  .mdn-Icon-tv,
  .icon-celular,
  .mdn-Icon-celular,
  .icon-telefone,
  .mdn-Icon-telefone-fixo,
  .advantage-item,
  .destaque-footer,
  .title-ofertas h2,
  .escolhaPlano,
  .desktop-title,
  .advantage,
  .destaque-combo h2,
  .cart-mdn-item-product-price,
  .ajuste-mobile,
  .helpText,
  .checkbox_mobile {
    color: var(--theme-color-neutral-lightest) !important;
    text-decoration: none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px var(--theme-color-neutral-darkest) inset;
    -webkit-text-fill-color: var(--theme-color-neutral-lightest);
  }

  .mensagem_de_agendamento {
    border-left-color: var(--theme-color-neutral-lightest) !important;
  }

  .ambiente-seguro .icon,
  .ambiente-seguro-footer .icon {
    filter: brightness(0) invert(1);
  }

  .date-picker-icon {
    .mdn-Icon-fechar {
      color: var(--theme-color-neutral-lightest) !important;
    }
  }

  .mdn-Steps-icon {
    background-color: var(--theme-color-neutral-darkest) !important;
  }

  .mdn-Steps-list-item {
    background: rgba(255, 255, 255, 0.24) !important;
  }

  .mdn-Steps-list-item--doing,
  .mdn-Steps-list-item--active {
    background-color: var(--theme-background-color--lightest) !important;
  }

  .cartText {
    color: var(--theme-text-color-Button--global) !important;
  }

  #carrinho-selecao,
  .carrinho-desktop,
  .carrinho-mobile {
    .cart-mdn {
      background-color: var(--theme-color-neutral-darkest);
      border: 1px solid var(--theme-color-neutral-lightest);

      .cart-mdn-header {
        border-bottom: 1px solid var(--theme-color-neutral-lightest);
        .mdn-Avatar {
          border: none;
        }
      }

      .cart-mdn-products {
        .mdn-Topic {
          button {
            .mdn-Icon-lixeira {
              color: var(--theme-text-color-Button--global);
              text-decoration: underline;
            }

            &:hover {
              .mdn-Icon-lixeira {
                text-decoration: none !important;
              }
            }
          }
        }

        .benefits-row {
          .mdn-Link {
            padding: 0 0;
          }

          .mdn-Avatar-notification {
            color: var(--theme-text-color-Button--global) !important;
            margin-top: 0.5rem !important;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .cart-mdn-item-selected-resources-row {
          .mdn-Avatar-notification {
            color: var(--theme-text-color-Button--global) !important;
            right: auto !important;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .cart-mdn-offer-details {
        button {
          color: var(--theme-text-color-Button--global) !important;
          text-decoration: underline !important;

          :hover {
            text-decoration: none !important;
          }
        }
      }

      .cart-mdn-bottom-price-row {
        background-color: transparent !important;

        .mdn-Price {
          .mdn-Price-prefix {
            color: var(--theme-color-neutral-lightest) !important;
          }
        }

        .mdn-annual-saving-seal {
          border: 1px solid var(--theme-color-neutral-lightest);
        }
      }
    }
  }

  header {
    .carrinho {
      .icon-carrinho:before {
        color: var(--theme-text-color-Button--global) !important;
      }

      .qtd {
        background-color: var(--theme-color-neutral-darkest) !important;
        color: var(--theme-text-color-Button--global) !important;
        border: 1px solid var(--theme-text-color-Button--global) !important;
        box-sizing: content-box !important;
        left: 47% !important;
      }
    }
  }

  .adicionais-content {
    .mdn-jumbotron {
      background-color: transparent !important;
      border-color: var(--theme-color-neutral-lightest);
      background-image: none !important;
    }

    .mdn-Radio {
      border-color: var(--theme-text-color-Button--global);
    }

    .mdn-Radio.active {
      border-width: 5px;
      border-color: var(--theme-text-color-Button--global) !important;
    }

    .conectividade {
      .box-connect {
        .img-pontoultra,
        .img-wifiplus {
          filter: brightness(0) invert(1);
        }
      }
    }

    .lista-item {
      background-color: none !important;
      border: 1px solid var(--theme-color-neutral-lightest);

      .shadow {
        background-color: var(--theme-color-neutral-darkest) !important;

        .lista-item-content {
          .description {
            color: var(--theme-text-color-Button--global) !important;
            cursor: pointer;
            text-decoration: underline !important;

            &:hover {
              text-decoration: none !important;
            }
          }
        }

        .lista-item-cta {
          .mdn-Button {
            background: var(--theme-background-color-Button--global) !important;
            border: 1px solid var(--theme-border-color-Button--global) !important;
            color: var(--theme-text-color-Button--global) !important;

            .mdn-Topic-symbol,
            .mdn-Topic-description {
              color: var(--theme-text-color-Button--global) !important;
            }
          }
        }
      }
    }

    .footer {
      background-color: var(--theme-color-neutral-darkest) !important;
    }
  }

  .button-add-dependent {
    .mdn-Topic {
      i,
      p {
        color: var(--theme-text-color-Button--global) !important;
        text-decoration: underline;
      }

      &:hover {
        i,
        p {
          text-decoration: none;
        }
      }
    }
  }

  .additional-line .btn-remover span {
    color: var(--theme-text-color-Button--global) !important;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .checkout-content
    .mdn-Steps-Icons-container
    .mdn-Steps-list-item--active::before {
    content: none !important;
    display: none !important;
  }

  .banner {
    background: var(--theme-color-neutral-darkest) !important;
    border: 1px solid var(--theme-text-color-Link--inverse) !important;
  }

  .banner img {
    margin-left: -8px;
  }

  .app-acordeon,
  .cont-planos {
    border: 1px solid var(--theme-color-neutral-lightest) !important;
    background: var(--theme-color-neutral-darkest) !important;
    color: var(--theme-color-neutral-lightest) !important;
  }

  .item-destaque {
    border: 2px solid var(--theme-color-neutral-lightest) !important;
    background: var(--theme-color-neutral-darkest) !important;
  }

  .btn-quero-destaque {
    color: var(--theme-text-color-Button--global) !important;
    background: var(--theme-color-neutral-darkest) !important;
    border: 2px solid var(--theme-text-color-Link--inverse) !important;
    text-decoration: underline;
  }

  .product {
    box-shadow: none !important;
    color: var(--theme-text-color-Button--global) !important;
    background: var(--theme-color-neutral-darkest) !important;
    border-collapse: separate !important;

    tbody.content-advantages tr td {
      border: 1px solid var(--theme-text-color-Button--global);
      border-radius: 8px 8px 0 0;
    }
  }

  .boxSelos,
  .price-de-por,
  .more-advantages,
  .close-advantages,
  .content-footer {
    border: 1px solid var(--theme-text-color-Link--inverse) !important;
    color: var(--theme-text-color-Link--inverse) !important;
    background: none !important;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  .price-context span {
    color: var(--theme-text-color-Link--inverse) !important;
  }

  .close-advantages {
    border: none !important;
    border-bottom-width: 1px !important;
  }

  .advantage-wifi_plus_gratis,
  .dobro-velocidade,
  .advantage-now {
    color: var(--theme-color-neutral-lightest) !important;
    border: 1px solid var(--theme-text-color-Link--inverse) !important;
    background: var(--theme-color-neutral-darkest) !important;
  }

  .contrast_icon,
  .internet-arrow-left,
  .internet-arrow-right,
  .celular-arrow-left,
  .celular-arrow-right,
  .televisao-arrow-left,
  .televisao-arrow-right,
  .telefone-arrow-left,
  .telefone-arrow-right {
    filter: brightness(0) saturate(100%) invert(97%) sepia(85%) saturate(4468%)
      hue-rotate(326deg) brightness(110%) contrast(109%) !important;
  }

  .linha-verde {
    background-color: var(--theme-color-neutral-darkest) !important;
    border: 1px solid var(--theme-color-neutral-lightest) !important;
  }

  .monte-sua-combinacao-content {
    .selected-pai {
      color: var(--theme-color-neutral-lightest) !important;
      border: 3px solid var(--theme-text-color-Link--inverse) !important;

      &::before {
        background-image: url(/assets/img/checked-contrast.png);
      }
    }

    #tv {
      h2 .now {
        .now-title {
          display: none;
        }
        .now-title-contrast {
          display: inline !important;
        }
      }
    }
  }

  .monte-sua-combinacao-content
    .product-category
    .ngucarousel
    table.product
    thead
    th {
    background: var(--theme-color-neutral-darkest) !important;
    border: 1px solid var(--theme-text-color-Link--inverse) !important;
    border-bottom: none !important;
    border-radius: 8px 8px 0 0 !important;
  }

  .monte-sua-combinacao-content
    .product-category
    .ngucarousel
    table.product
    tfoot
    td {
    padding: 0;

    a {
      background: var(--theme-color-neutral-darkest) !important;
      border: 1px solid var(--theme-text-color-Link--inverse) !important;
      border-radius: 0 0 8px 8px !important;
      border-top: none !important;
    }
  }

  .btn-quero-destaque:hover {
    text-decoration: none;
  }

  table tbody tr:nth-of-type(2n + 0),
  table tbody tr:nth-of-type(2n + 1) {
    background: var(--theme-color-neutral-darkest) !important;
  }

  .carousel-points {
    li {
      background: var(--theme-text-color-Link--inverse);
      border: var(--theme-text-color-Link--inverse);
      opacity: 100%;
    }

    li.active {
      background: var(--theme-color-neutral-lightest);
      border: var(--theme-color-neutral-lightest);
    }
  }

  .btn-next {
    background: var(--theme-color-neutral-darkest) !important;

    a {
      background: none !important;
      border: 1px solid var(--theme-text-color-Link--inverse) !important;
      border-radius: 30px;
    }
  }

  .cart-mdn-social-media-share {
    .link-social-media-share {
      .mdn-Topic-description,
      .mdn-Topic-symbol {
        color: var(--theme-text-color-Button--global);
        text-decoration: underline;
      }

      &:hover {
        .mdn-Topic-description,
        .mdn-Topic-symbol {
          text-decoration: none !important;
        }
      }
    }
  }

  .social-media-share {
    button {
      svg {
        fill: var(--theme-text-color-Button--global) !important;
      }

      &.whatsapp_icon {
        svg {
          circle {
            fill: var(--theme-text-color-Button--global) !important;
          }
          path {
            fill: var(--theme-color-neutral-darkest);
          }
        }
      }
    }
  }

  .c-confirmation-step__item--circle-enabled,
  .c-confirmation-step__item--circle-disabled {
    background-color: var(--theme-background-color--default) !important;
    color: var(--theme-text-color--light) !important;
    border: 3px solid var(--theme-text-color--light) !important;
  }

  .c-title {
    color: var(--theme-text-color--light) !important;
  }

  .item-alert {
    border: 1px solid var(--theme-text-color--light) !important;

    h2,
    span {
      color: var(--theme-text-color--light) !important;
    }
  }
}

//BlackFriday Theme
.black-Friday {

  thead th {
    background: black;
  }
  .mdn-Menu {
    background-color: #252525 !important;
  }

  .mdn-Menu-top {
    background-color: #000000 !important;
    color: var(--theme-background-color--lightest) !important;
  }

  .mdn-Footer {
    background-color: #000000 !important;
  }

  .cart-mdn-header,
  .mdn-Icon-carrinho{
    background-color: #252525 !important;
  }

  .cart-mdn-icon {
    background-color: none;
  }

  .cart-mdn-icon {
    background-color: #252525 !important;
  }

  .mdn-Button--secondary {
    color: #212529 !important;
  }

  .item-destaque {
    // background: linear-gradient(130deg, #464a51 5%, black 60%) !important;
    background: radial-gradient(164.36% 150.73% at 10.81% 7.82%, #4d5158 10%, #51555D 0%, #000 80%) !important;

    .icon {
      color: #FFBC4A !important;
    }
  }

  .item-destaque-nome,
  .advantage-item,
  .txt-10px,
  .cont-preco {
    color: var(--theme-color-neutral-lightest) !important;
  }

  .mdn-Button--global {
    background-color: #FFBC4A;
  }
}
